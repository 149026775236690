// Theming
@import '../src/themes/palette';
@import './themes/mixins/mat-icons-size';

// Overrides
html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Catamaran', sans-serif;
}

.container {
  position: relative;
  padding: 40px 15px;
  display: grid;
  min-height: calc(100vh - 266px);
  justify-items: center;
  align-items: center;

  &.background {
    background: url('/assets/background.svg');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
  }
}

// NOTE: check for dialogs transitions
.cdk-overlay-backdrop {
  transition: opacity 10ms cubic-bezier(0.25, 0.8, 0.25, 1);
}
