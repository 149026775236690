@use '@angular/material' as mat;
// Plus imports for other components in your app.

@import 'palette';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// The warn palette is optional (defaults to red).
$sardex-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$sardex-app-theme: mat.define-light-theme(
  $sardex-app-primary,
  $sardex-app-accent,
  $sardex-app-warn
);

// Debug the theme
// @debug 'sardex-app-theme: #{$sardex-app-theme}';

// Define a custom typography config that overrides the font-family
$sardex-typography: (
  display-4: mat.define-typography-level(150px, 205px, 900, 'Catamaran', 1px),
  display-3: mat.define-typography-level(100px, 136px, 800, 'Catamaran', 1px),
  display-2: mat.define-typography-level(80px, 109px, 900, 'Catamaran', 0.5px),
  display-1: mat.define-typography-level(50px, 58px, 600, 'Catamaran', 0.5px),
  headline: mat.define-typography-level(32px, 43px, 700, 'Catamaran', 0),
  title: mat.define-typography-level(25px, 30px, 400, 'Catamaran', 0.17px),
  subheading-2: mat.define-typography-level(18px, 24px, 700, 'Catamaran', 0.16px),
  subheading-1: mat.define-typography-level(14px, 19px, 600, 'Catamaran', 0.09px),
  body-2: mat.define-typography-level(18px, 22px, 400, 'Catamaran', 0.47px),
  body-1: mat.define-typography-level(16px, 20px, 400, 'Catamaran', 0.23px),
  button: mat.define-typography-level(15px, 20px, 600, 'Catamaran', 1.25px),
  caption: mat.define-typography-level(12px, 16px, 400, 'Catamaran', 0.37px),
  input: mat.define-typography-level(16px, 1.125, 400, 'Catamaran', 1px),
);

// @include mat-base-typography($sardex-typography);
// Override the typography in the core CSS.
@include mat.core($sardex-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($sardex-app-theme);

// Material overrides
.mat-checkbox-layout {
  white-space: normal !important;
}

a {
  color: mat.get-color-from-palette($srd-accent, 500);
}

h1,
h2,
h3,
h4,
h5,
h6,
.mat-h1,
.mat-headline,
.mat-h2,
.mat-title,
.mat-h3,
.mat-subheading {
  color: mat.get-color-from-palette($srd-primary, 500);
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-raised-button {
  text-transform: uppercase;
  border-radius: 0 !important;

  &.mat-big-button {
    font-size: 18px;
    min-width: 245px;
    line-height: 55px;
  }
}

.mat-chip.mat-standard-chip {
  background-color: mat.get-color-from-palette($srd-accent, 100);
}

.mat-raddio-label-flex-start {
  .mat-radio-button {
    .mat-radio-label {
      align-items: flex-start !important;
    }
  }
}

.mat-form-field {
  .mat-form-field-underline {
    background-color: mat.get-color-from-palette($sardex-app-blue);

    &::before {
      background-color: mat.get-color-from-palette($sardex-app-blue) !important;
    }
  }
}

.mat-card {
  border-radius: 0 !important;
}

.mat-accordion .mat-expansion-panel.mat-elevation-z0 {
  &:last-of-type {
    border-radius: 0;
  }

  border-bottom: 1px solid map-get($srd-colors, gray);
}

// Fix overflow for dialogs
.mat-dialog-container .mat-dialog-content {
  padding-top: 4px;
  padding-bottom: 4px;
}
